export default [
  {
    iso_639_1: 'ja',
    english_name: 'Japanese',
    name: 'Nhật Bản',
    short_name: 'nhat-ban',
  },
  {
    iso_639_1: 'ko',
    english_name: 'Korean',
    name: 'Hàn Quốc',
    short_name: 'han-quoc',
  },
  {
    iso_639_1: 'cn',
    english_name: 'Chinese',
    name: 'Trung quốc',
    short_name: 'trung-quoc',
  },
  {
    iso_639_1: 'th',
    english_name: 'Thai',
    name: 'Thái Lan',
    short_name: 'thai-lam',
  },
  {
    iso_639_1: 'vi',
    english_name: 'Vietnamese',
    name: 'Việt Nam',
    short_name: 'viet-nam',
  },
  {
    iso_639_1: 'hi',
    english_name: 'Hindi',
    name: 'Ấn Độ',
    short_name: 'an-do',
  },
  {
    iso_639_1: 'en',
    english_name: 'English',
    name: 'Âu Mỹ',
    short_name: 'au-my',
  },
  {
    iso_639_1: 'ru',
    english_name: 'Russian',
    name: 'Nga',
    short_name: 'nga',
  },
  {
    iso_639_1: 'fr',
    english_name: 'French',
    name: 'Pháp',
    short_name: 'phap',
  },
  {
    iso_639_1: 'es',
    english_name: 'Spain',
    name: 'Tây Ban Nha',
    short_name: 'tay-ban-nha',
  },
  {
    iso_639_1: 'no',
    english_name: 'Norway',
    name: 'Na Uy',
    short_name: 'na-uy',
  },
  {
    iso_639_1: 'de',
    english_name: 'Germany',
    name: 'Đức',
    short_name: 'duc',
  },
  {
    iso_639_1: 'fi',
    english_name: 'Finland',
    name: 'Phần Lan',
    short_name: 'phan-lan',
  },
  {
    iso_639_1: 'id',
    english_name: 'Indonesia',
    name: 'Indonesia',
    short_name: 'indonesia',
  },
  {
    iso_639_1: 'hu',
    english_name: 'Hungary',
    name: 'Hungary',
    short_name: 'hungary',
  },
  {
    iso_639_1: 'ms',
    english_name: 'Montserrat',
    name: 'Montserrat',
    short_name: 'montserrat',
  },
  {
    iso_639_1: 'nl',
    english_name: 'Netherlands',
    name: 'Hà Lan',
    short_name: 'ha-lan',
  },
  {
    iso_639_1: 'pl',
    english_name: 'Poland',
    name: 'Ba Lan',
    short_name: 'ba-lan',
  },
  {
    iso_639_1: 'pt',
    english_name: 'Portugal',
    name: 'Bồ Đào Nha',
    short_name: 'bo-dao-nha',
  },
  {
    iso_639_1: 'tr',
    english_name: 'Turkey',
    name: 'Thổ Nhĩ Kỳ',
    short_name: 'tho-nhi-ky',
  },
];
